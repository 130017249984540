var EfbHelperAutocomplete = function () {

    var _private   = {};
    var inst       = this;
    this.endpoint  = 'unknown';
    this.field     = 'unknown';
    this.sort      = 'asc';
    this.delay     = 500;
    this.minLength = 2;
    this.limit     = 10;
    this.format    = '#term#%';
    this.params    = {filter: {},order: {}};

    this.init = function (inputElement) {
        var data      = inputElement.data();
        inst.format   = data.fieldFormat || inst.format;
        inst.endpoint = data.objectType || inst.format;
        inst.field    = data.objectField || inst.field;
        inst.limit    = data.objectLimit || inst.limit;
        _private.initAutoComplete(inputElement);
        return this;
    };

    this.initByACElements = function (elements) {
        elements.each(function (i, inputElement) {
            inputElement = $(inputElement);
            if (!inputElement.hasClass('autocompleteObject')) {
                return;
            }
            new EfbHelperAutocomplete().init(inputElement);
        });
        return this;
    };

    _private.initAutoComplete = function (inputElement) {
        var request;
        var inputV = inputElement.next('input[type=hidden]');
        inputElement.parent('div.formelement').addClass('ui-front');
        inputElement.efbAutocomplete({
            minLength: inst.minLength,
            delay:     inst.delay,
            source:    function (r, response) {
                request = $.ajax({
                    url:      urlHelper.getSimpleUrl(inst.endpoint, 'search'),
                    dataType: 'json',
                    data:     _private.getRequestParams(inputElement, r.term),
                    success:  function (data) {
                        response($.map(data, function (object) {
                            object.label = object[inst.field];
                            return object;
                        }));
                    },
                    complete: function () {
                        inputElement.removeClass('ui-autocomplete-loading');
                    },
                    error:    function () {
                        inputElement.removeClass('ui-autocomplete-loading');
                    }
                });
            },
            select:    function (event, ui) {
                inputElement.removeClass('searching');
                inputV.val(ui.item.object_id).change();
                inst.select.apply(this, [ui.item.object_id, ui.item, inputV]);
            },
            search:    function () {
                inputV.val('').change();
                inputElement.removeClass('ui-autocomplete-loading');
                inputElement.addClass('searching');
                inst.select.apply(this, [undefined, undefined, inputV]);
                if (!_.isUndefined(request)) {
                    request.abort();
                    request = undefined;
                }
            }
        });
    };

    _private.getRequestParams = function (input, searchTerm) {
        inst.params.order[inst.field]       = inst.sort;
        inst.params.filter[inst.field]      = inst.params.filter[inst.field] || {};
        inst.params.filter[inst.field].like = inst.format.replace('#term#', searchTerm);
        inst.params.limit                   = inst.limit;
        return inst.params;
    };

    this.select = function () {};
};